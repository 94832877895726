<template>
    <div class="container page">
        <van-nav-bar title="세부" class="nav-bar">
            <template #left>
                <van-icon name="arrow-left" color="#fff" @click="back()" />
            </template>
        </van-nav-bar>
        <div class="detail-container">
            <div v-for="item in dataList"  :key="item.id" class="detail-item">
                <van-row >
                    <van-col span="12">
                        <span class="title-label">
                            이슈 번호: 
                        </span>
                        <span class="info">
                            {{item.expect}}
                        </span>
                    </van-col>
                    <van-col span="12">
                        <span class="title-label">
                            이익과 손실: 
                        </span>
                        <span class="info">
                            {{item.win_text}}
                        </span>
                    </van-col>
                    <van-col span="12">
                        <span class="title-label">
                            베팅 금액: 
                        </span>
                        <span class="info">
                            {{item.money}}
                        </span>
                    </van-col>
                    <van-col span="12">
                        <span class="title-label">
                            이익: 
                        </span>
                        <span class="info">
                            {{item.is_win===2?0: item.profit}}
                        </span>
                    </van-col>
                    <van-col span="12">
                        <span class="title-label">
                            유형: 
                        </span>
                        <span class="info">
                            {{item.status_text}}
                        </span>
                    </van-col>

                    <van-col span="12">
                        <span class="title-label">
                            배팅 내역: 
                        </span>
                        <span class="info" v-if="item.type=== '大'">
                            대
                        </span>
                        <span class="info" v-if="item.type=== '小'">
                            소
                        </span>
                        <span class="info" v-if="item.type=== '单'">
                            홀
                        </span>
                        <span class="info" v-if="item.type=== '双'">
                            짝
                        </span>
                    </van-col>

                    <van-col span="24">
                        <span class="title-label">
                            주문 시간: 
                        </span>
                        <span class="info">
                            {{item.create_time}}
                        </span>
                    </van-col>
                </van-row>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    data() {
        return {
            dataList: []
        };
    },
    methods: {
        back() {
            return window.history.back();
        },
    },
    created(){
        if(this.$route.query.data){
            this.dataList = JSON.parse( this.$route.query.data);
        }
        console.log('111',this.$route.query);

        console.log('111',JSON.parse( this.$route.query.data));
    }
}

</script>

<style lang='less' scoped>
.detail-container{
    padding: 10px;
    .detail-item{
        padding: 10px;
        background-color: #fff;
        margin-bottom: 20px;
        border-radius: 10px;
        .title-label{
            color: #c24491;
            font-weight: 500;

        }
        .info{
            color: #333;
            font-size: 28px;
        }
        ::v-deep .van-col{
            margin-bottom: 8px;
        }
    }
}
</style>